import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
//component
//scss
import '../../scss/block/productList.scss'

const ProductList = ({ data, title }) => {
  return (
    <div className='productList'>
      <div className='container'>
        {title ? <h4>{title}</h4> : ''}
        <div className='list'>
          {data.map((item, index) => {
            return (
              <div key={index} className='product'>
                <Link
                  to={`/produits/${item.productType.slug}/${item.slug}`}
                  className='flex'
                >
                  <GatsbyImage
                    image={item.img.gatsbyImageData}
                    alt={item.img.title}
                  />
                </Link>
                <h3>
                  <Link
                    to={`/produits/${item.productType.slug}/${item.slug}`}
                    className='flex'
                  >
                    {item.title}
                  </Link>
                </h3>
                <p>{item.subtitle}</p>
                <Link
                  to={`/produits/${item.productType.slug}/${item.slug}`}
                  className='button second'
                >
                  En savoir plus
                </Link>
                <Link
                  to={`/contact`}
                  className='button'
                  state={{ product: item.title, sujet: 'Information produits' }}
                >
                  Demande d'information
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ProductList
