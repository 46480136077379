import React, { Component } from 'react'
import { navigate } from 'gatsby'
//scss
import '../../scss/block/productListSelect.scss'
export default class ProductListSelect extends Component {
  state = {
    data: this.props.select,
    location: this.props.location.pathname,
  }
  handleChange(e) {
    navigate(e.target.value)
  }
  render() {
    return (
      <div className='productListSelect'>
        <div className='container'>
          {' '}
          <p>{this.props.switchText}</p>
          <select
            onChange={this.handleChange}
            onBlur={this.handleChange}
            value={this.state.location}
          >
            {this.state.data.map((item, index) => (
              <React.Fragment key={index}>
                <option value={`/produits/${item.slug}`}>{item.title}</option>
                {item.colorVar !== null
                  ? item.colorVar.map((itm, idx) => (
                      <option
                        key={idx}
                        value={`/produits/${item.slug}/${itm.slug}`}
                      >
                        - {item.title} {itm.title}
                      </option>
                    ))
                  : ''}
              </React.Fragment>
            ))}
          </select>
        </div>
      </div>
    )
  }
}
