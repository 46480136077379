import React from 'react'
import { graphql } from 'gatsby'
//component
import Seo from '../components/SEO'
import ProductList from '../components/product/ProductList'
import ProductListSelect from '../components/product/ProductListSelect'

const TemplateProductList = ({ data, pageContext, location }) => {
  return (
    <>
      <Seo title={pageContext.title} slug={location.pathname} />

      {data.d.nodes[0].colorVar === null ? (
        <ProductList data={data.c.nodes} location={location} />
      ) : (
        data.d.nodes[0].colorVar.map((item, index) => {
          return (
            <ProductList
              data={data.c.nodes.filter((i) => i.color.slug === item.slug)}
              title={`${pageContext.title} ${item.title}`}
              key={index}
              location={location}
            />
          )
        })
      )}
      <ProductListSelect
        select={data.menu.nodes}
        location={location}
        switchText={data.switchText.switchProductType.switchProductType}
      />
    </>
  )
}
export const query = graphql`
  query ($product: String) {
    c: allContentfulProduits(
      filter: { productType: { slug: { eq: $product } } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        title
        slug
        subtitle
        productType {
          slug
        }
        color {
          slug
        }
        img {
          title
          gatsbyImageData(
            width: 400
            height: 400
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
    d: allContentfulProductType(filter: { slug: { eq: $product } }) {
      nodes {
        colorVar {
          slug
          title
        }
      }
    }
    menu: allContentfulProductType(sort: { fields: order, order: ASC }) {
      nodes {
        colorVar {
          title
          slug
        }
        title
        slug
      }
    }
    switchText: contentfulConfig {
      switchProductType {
        switchProductType
      }
    }
  }
`

export default TemplateProductList
